import React, { useState } from "react";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import TopFormWithoutImage from "../../components/TopFormWithoutImage";
import Cards from "../../components/chat-channel/Cards";
import AnchorLink from "react-anchor-link-smooth-scroll";
import styled from "styled-components";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../assets/images/workflow_automate/automate_header.png");

const icon1 = require("../../assets/automate/home/benefits_icon_1.png");
const icon2 = require("../../assets/automate/home/benefits_icon_2.png");
const icon3 = require("../../assets/automate/home/benefits_icon_3.png");
const icon4 = require("../../assets/automate/home/benefits_icon_4.png");
const icon5 = require("../../assets/automate/home/benefits_icon_5.png");
const icon6 = require("../../assets/automate/home/benefits_icon_6.png");

const Section = styled.section`
  width: 100%;
  float: left;
  display: flex;
  margin-bottom: 60px;
  justify-content:space-between;
`;
const Tab = styled.div`
  font-size: 20px;
  cursor: pointer;
  background: inherit;
  width: 100%;
  border: 0;
  outline: 0;
  padding: 20px;
  ${({ active }) =>
    active &&
    `
    background: #F2FFFA 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    overflow: overlay;
  `}
`;
const Header = styled.h4`
  font-family: Roboto-Medium;
    font-size: 20px;
    color: #333;
    padding-bottom: 5px;
    width: 100%;
    float: left;
    margin-bottom: 0;
    line-height: normal;
}
`;
const Paragraph = styled.p`
flex: 1 1 auto;
color: #416183 !important;
font-family: Roboto-Regular;
line-height: 1;
margin-bottom: 0px;
font-size: 20px;
width:100%;
float:left;
}
`;
const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  flex: 0 0 49%;
    max-width: 49%;
`;
const ContentGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  flex: 0 0 49%;
  max-width: 49%;
`;
const Tabalign = styled.div`
width: 100%;
display: flex;
flex-wrap: wrap;
height: 100%;
align-items: center;
`


export default function FeatureHomePage() {
  return (
    <>
      <TitleAndMetaTags
         title="Workflow Automation Beta Registration | Workativ Automate"
         description="Free Beta Registration for Workflow Automation Platform. Be the first to try workativ automate — a free no-code workflow automation platform."
         keywords={["Workflow Automation"]}
         ogImage={topImage}
      />
      <Container>
        <section className="workflow_automation_wrapper workflow_autoamte_wrapper trigger_page automate_product">
          <Layout
            backgroundColor={"bg_header_automation"}
            logoFor="AUTOMATE"
            product="AUTOMATE"
          >
            <div className="automate_homepage automate_homepage_home">
              <TopFormWithoutImage>
                <TopFormWithoutImage.Header>
                  Be the first to try workativ automate —a no code workflow
                  automation platform.
                </TopFormWithoutImage.Header>
                <TopFormWithoutImage.Content>
                  Through this program, you get to experience workativ automate,
                  a no-code platform to help companies of all sizes to quickly
                  connect various apps and automate workflows easily in minutes.
                </TopFormWithoutImage.Content>
                <RequestForm product="automate" AutomateCTA  autoamteButton isFooterForm={false} />
                <p className="cta_autoamte">
                  We need your feedback, inputs, and experience to help us build
                  a great product!
                </p>
              </TopFormWithoutImage>
              <TabGroup header="What does it include?"/>
              
              <HowItsWorkPage />
              <section className="center_image_home cards_landing_page">
                <div className="container">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="main-slider-left">
                        <h3>What’s are the benefits?</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <Cards cards={cardData} />
              </section>
              <div className="automate_pricing">
              <Faq />
              </div>
            
              <RequestForm product="automate" content="We need your feedback, inputs, and experience to help us build a great product!" AutomateCTA automateContent autoamteButton automateName isFooterForm={true} />
            </div>
          </Layout>
        </section>
      </Container>
    </>
  );
}

function Faq() {
  return (
    <React.Fragment>
      <div className="mob_accordian mob_accordian_faq">
        <section className="accordian_landing trial_accordian_landing">
          <div className="container">
            <h2>Frequently Asked Questions</h2>
            <div className="col-12 pl-0 faq_landing">
              <Accordion
                id="accordion"
                className="accordion pl-0"
                preExpanded={["a"]}
                allowZeroExpanded={true}
              >
                <div className="accordion-wrapper pricing_faq">
                  {faqData.map((data) => (
                    <AccordionItem uuid={data.uuid}>
                      <AccordionItemHeading>
                        <AccordionItemButton>{data.header}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p
                          dangerouslySetInnerHTML={{ __html: data.paragraph }}
                        />
                        {data.paragraph1 && (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data.paragraph1,
                            }}
                          />
                        )}
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </div>
              </Accordion>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}

export const faqData = [
  {
    header: "What’s the duration of the Beta program?",
    paragraph: "It is a 30-day program",
    active: "active",
    uuid: "a",
  },
  {
    header: "Will I receive support?",
    paragraph:
      "Yes, we will get back to you within 24 hours of sending your support questions, via email. We also may provide phone support depending on the level of support required.",
  },
  {
    header: "What happens to my data after the beta program? ",
    paragraph:
      "At the end of the beta program, if you were to purchase Workativ Automate we will migrate all your data into your paid workspace/account so you can start using the application immediately without any hassle.",
  },
  {
    header:
      "If I’m not interested in purchasing your product immediately after the beta program, what happens to my data? ",
    paragraph:
      "Your data is deleted after two months from the end of the beta program. We will notify you via email before we delete your data.",
  },
  {
    header: "Can I purchase Workativ Automate during the beta program?",
    paragraph:
      "Yes, please send an email to <a href='mailto:beta@workativ.com'>beta@workativ.com</a> and we'll help you with the purchase.   ",
  },
  {
    header: " Do you have a cap on number of users?",
    paragraph:
      "From each organization, only one user would be allowed to work on the application as an admin. ",
  },
  {
    header: "How do I contact you?",
    paragraph:
      "Please send your questions or emails to <a href='mailto:beta@workativ.com'>beta@workativ.com</a>",
    paragraph1: "We will respond to all your emails at the earliest.",
  },
];

const cardData = [
  {
    content: "Preview the latest and upcoming features before everybody else!",
    image: icon1,
  },
  {
    content: "Send your feedback and help us make Workativ Automate better!.",
    image: icon2,
  },
  {
    content:
      "In return you can qualify for “Dev Partner” program that offers several benefits, free of cost. ",
    image: icon3,
  },
  {
    content: "Free subscription for 6 months (selected beta customers).",
    image: icon4,
  },
  {
    content: "Early bird introductory pricing.",
    image: icon5,
  },
  {
    content: "Free on-boarding and training.",
    image: icon6,
  },
];

function HowItsWorkPage() {
  return (
    <section className="how_it_section how_it_page_content">
      <div className="how_it_section_page">
        <div className="container">
          <div className="main-slider-left">
            <h2>How do I participate in this program?</h2>
          </div>
          <div className="row">
            {/*How it Works Content*/}
            <div className="x_scroll_page">
              <div className="how_it_works_header">
                <div className="benefits-workplace-content how_it_content how_it_content_before">
                  <img
                    src={require("../../assets/automate/home/how_step_1.png")}
                    alt=" Step 1"
                  />
                  <h3>
                    <AnchorLink
                      offset={180}
                      className="url_manipulation scroll_page"
                      href="#section-1"
                    >
                      Step 1
                    </AnchorLink>
                  </h3>
                  <p>You sign-up and become an early believer.</p>
                </div>
              </div>
              {/*How it Works Content*/}
              <div className="how_it_works_header">
                <div className="benefits-workplace-content  how_it_content how_it_content_before">
                  <img
                    src={require("../../assets/automate/home/how_step_2.png")}
                    alt="Step 2"
                  />
                  <h3>
                    <AnchorLink
                      offset={180}
                      className="url_manipulation scroll_page"
                      href="#section-2"
                    >
                      Step 2
                    </AnchorLink>
                  </h3>
                  <p>We show you what we’re building.</p>
                </div>
              </div>
              {/*How it Works Content*/}
              <div className="how_it_works_header">
                <div className="benefits-workplace-content how_it_content">
                  <img
                    src={require("../../assets/automate/home/how_step_3.png")}
                    alt="Step 3"
                  />
                  <h3>
                    <AnchorLink
                      offset={180}
                      className="url_manipulation scroll_page"
                      href="#section-3"
                    >
                      Step 3
                    </AnchorLink>
                  </h3>
                  <p>
                    You give your feedback, suggestions, thoughts on making it
                    better/easier.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const image1 = require("../../assets/automate/home/automate_img_1.png");
const image2 = require("../../assets/automate/home/automate_img_2.png");
const image3 = require("../../assets/automate/home/automate_img_3.png");
const image4 = require("../../assets/automate/home/automate_img_4.png");

const types = [
  {
    header: "Workflow Builder",
    content: "Build single or multi-step workflows",
    image: image1,
    uuid:"a",
    alt:"Workflow Builder"
  },
  {
    header: "Workflow Marketplace",
    content: "Pre-built triggers and actions for apps",
    image: image2,
    alt:"Workflow Marketplace"
  },
  {
    header: "On-prem connect",
    content: "Connect cloud with on-prem apps to execute workflows",
    image: image3,
    alt:"on-prem apps to execute workflows"
  },
  {
    header: "Analytics",
    content: "Workflow execution report",
    image: image4,
    alt:"Workflow automation analytics"
  },
];

function TabGroup ({header}) {
  const [active, setActive] = useState(types[0]);
  return (
    <>
      <div className="tabgroup_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Section>
                <ButtonGroup className="col-lg-6 col-md-6 col-12 pl-0">
                <h2>{header}</h2>
                <Tabalign>
                  {types.map((type) => (
                    <Tab
                      key={type}
                      active={active === type}
                      onClick={() => setActive(type)}
                    >
                      <Header>{type.header}</Header>
                      <Paragraph>{type.content}</Paragraph>
                    </Tab>
                  ))}
                  </Tabalign>
                </ButtonGroup>
                <ContentGroup className="col-lg-6 col-md-6 col-12 pr-0">
                  <div className="image_tabgroup">
                    <img src={active.image} alt={active.alt}/>
                  </div>
                </ContentGroup>
              </Section>
            </div>
          </div>
        </div>
      </div>

      <>
      <div className="mobile_view_tab">
      <div className="mob_accordian mob_accordian_faq">
        <section className="accordian_landing trial_accordian_landing">
          <div className="container">
            <h2>
              {header}
            </h2>
            <div className="col-12 pl-0 faq_landing">
              <Accordion
                id="accordion"
                className="accordion pl-0"
                preExpanded={["a"]}
                
              >
                <div className="accordion-wrapper pricing_faq">
                  {types.map((data) => (
                    <AccordionItem uuid={data.uuid}>
                      <AccordionItemHeading>
                        <AccordionItemButton>{data.header}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p
                          dangerouslySetInnerHTML={{ __html: data.content }}
                        />
                          <div className="image_tabgroup">
                    <img src={data.image} alt={data.alt}/>
                  </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </div>
              </Accordion>
            </div>
          </div>
        </section>
      </div>   </div>
      </>
    </>
  );
}
