import React from "react";

TopFormWithoutImage.Header = ({ children }) => {
    return <h1>{children}</h1>;
  };
  TopFormWithoutImage.Content = ({ children }) => {
    return <p>{children}</p>;
  };
  
  export default    function TopFormWithoutImage({ children, image, altImage }) {
    return (
      <section className="feature_inner_page  bg_feature_slider bg_feature_slider_wrapper trial_page_img">
    <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="main-slider-left">{children}</div>
            </div>
          </div>
        
        </div>
      </section>
    );
  }